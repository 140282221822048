import Keycloak from './keycloak.js'
import {computed} from "vue";
import keycloak_options from "./keycloak_options.js";

const _keycloak = Keycloak(keycloak_options)

export async function initKeycloak(init_options) {
  console.log("Installing keycloak", _keycloak, init_options)
  try {
    const authenticated = await _keycloak.init(init_options);
    console.log("Authenticated with Keycloak", authenticated);
    _keycloak.onTokenExpired = ()=>{
      console.log('[KEYCLOAK] token expired at ' + new Date().toISOString());
      _keycloak.updateToken(30).success((refreshed)=>{
          if (refreshed){
              console.log('[KEYCLOAK] token refreshed at ' + new Date().toISOString());
          }else {
              console.log('[KEYCLOAK] token not refreshed at ' + new Date().toISOString());
          }
      }).error(() => {
           console.error('Failed to refresh token at ' + new Date().toISOString());
      });
    }
  } catch (e) {
    console.error('Failed login via Keycloak', e);
    alert('Authentication through Keycloak failed: ' + e);
    _keycloak.logout();
  }
}

/**
 * Check if the user is authenticated and, if provided, if he has the right role.
 * @param roleRequired Optionally a role that is required for this operation.
 * @returns {null|boolean|boolean} True if authenticated and authorized, False if authenticated but not authorized, null if not authenticated.
 */
const checkAuthentication = (roleRequired) => {
  if (_keycloak.authenticated) {
    return !roleRequired || _keycloak.hasResourceRole(roleRequired);
  }
  return null;
}

/**
 * Check authentication before resolving every route.
 *
 * Used as follows:
 * router.beforeResolve(keycloakAuthBeforeResolveGuard)
 */
export function keycloakAuthBeforeResolveGuard(to, from, next) {
  // By default require authentication.
  if (to.meta.needsAuthentication === undefined || to.meta.needsAuthentication) {
    const authResult = checkAuthentication(to.meta.roleRequired);
    if (authResult === true) {
      // The user was authenticated, and has the app role (if necessary). Update the token.
      next()
    } else if (authResult === false) {
      // The user was authenticated, but did not have the correct role (is not authorized)
      // Redirect the user to an error page.
      next({name: 'Unauthorized'})
    } else {
      // The user is not authenticated. Should login.
      const origin = window.location.origin
      // The page is protected and the user is not authenticated. Force a login.
      _keycloak.login({redirectUri: origin + "/redirect_or_login?to=" + to.path})
    }
  } else {
    // This page did not require authentication
    next()
  }
}

export function useKeycloak() {
  function getKeycloak() {
    return _keycloak;
  }

  const login = () => {
    _keycloak.login({ redirectUri: window.location.origin });
  }

  const logout = () => {
    _keycloak.logout({ redirectUri: window.location.origin });
  }

  const isAuthenticated = computed(() => {
    return _keycloak.authenticated;
  });

  const getHeaders = () => {
    const token = _keycloak.token;
    return {
      'Authorization': `Bearer ${token}`
    };
  }

  return {
    checkAuthentication,
    getHeaders,
    isAuthenticated,
    login,
    logout,
    getKeycloak,
  }
}
