<template>
  <a-spin :spinning="is_loading" tip="Loading tree data...">
    <a-input-search v-model:value="searchValue" style="margin-bottom: 8px" placeholder="Search" />
    <a-tree
      :load-data="onLoadData"
      :tree-data="EDRTreeData"
      :show-line="true"
      v-model:expandedKeys="expandedKeys"
      :auto-expand-parent="autoExpandParent"
      v-model:selectedKeys="selectedKeys"
      @select="selectTreeOption"
      @expand="onExpand"
    >
        <template #title="{ title }">
          <span v-if="title.indexOf(searchValue) > -1">
            {{ title.substr(0, title.indexOf(searchValue)) }}
            <span style="color: #f50">{{ searchValue }}</span>
            {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
          </span>
          <span v-else>{{ title }}</span>
        </template>
    </a-tree>
  </a-spin>
</template>

<script>
import { API_HOST } from "../envconfig"
import axios from 'axios'
import { useEDRTree } from '../composables/EDRTree.js';

export default {
  name: 'EDRTree',
  props: {
    category: String,
    id: String,
    admin: Boolean,
  },
  components: {
    // 'sub-menu': EDRListSubMenu,
  },
  setup() {
    const { EDRTreeData, addNode, selectedKeys } = useEDRTree();
    return { EDRTreeData, addNode, selectedKeys };
  },
  emits: ['update:id', 'adminInfo'],
  data() {
    return {
      cat: this.category,
      is_loading: false,
      expandedKeys: [],
      searchValue: "",
      EDRTreeDataList: [],
      autoExpandParent: true
    };
  },
  watch: {
    category(val) {
      // console.log(val);
      this.getEDRList(val);
    },
    searchValue(val) {
      let expanded = [];  // if user clears the search box collapse everything
      if (val !== "") {
        expanded = this.EDRTreeDataList
          .map(item => {
            if (item.title.indexOf(val) > -1) {
              return this.getParentKey(item.key, this.EDRTreeData);
            }
            return null;
          })
          .filter((item, i, self) => item && self.indexOf(item) === i);
      }
      this.expandedKeys = expanded;
      this.searchValue = val;
      this.autoExpandParent = true;
    }
  },
  mounted() {
    this.getEDRList(this.category);

    // Check if there's an item_id in the route and select it (for deep-linking).
    const itemId = this.$route.params.item_id;
    if (itemId) {
      // If we are linking to a folder, we shouldn't do this. However I'm not sure how to figure out if it's a folder or
      // not. Nothing breaks if we do this for folders though.
      this.$emit('update:id', itemId);
    }
    // This will expand the tree to the selected item. Works for both folders and items.
    this.expandedKeys = [itemId];
  },
  methods: {
    getEDRList: function(category) {
      const path = `${API_HOST}/api/edr_list?category=` + category;
      console.debug("Loading tree for category: " + this.category);
      this.is_loading = true; // Set loading to true
      axios.get(path)
        .then((res) => {
          // console.log(res);
          this.treeData = res['data'];
          this.EDRTreeData = res['data'];

          this.EDRTreeDataList = [];  // Make sure to start with an empty list
          this.generateList(this.EDRTreeData);

          this.is_loading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          this.is_loading = false;
        });
    },
    onLoadData: function(treeNode) {
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          resolve();
          return;
        }

        const path = `${API_HOST}/api/edr_list/path?id=` + this.category;
        axios.get(path)
          .then((res) => {
            // console.log(res);
            treeNode.dataRef.children = res['data'];
            this.treeData = [...this.treeData];
            this.EDRTreeData = res['data'];

            this.EDRTreeDataList = [];  // Make sure to start with an empty list
            this.generateList(this.EDRTreeData);

            this.is_loading = false;
            resolve();
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
            this.is_loading = false;
          });
      });
    },
    selectTreeOption: function(selectedKeys, e) {
      // console.log(selectedKeys);
      // console.log(e);
      const selectedKey = selectedKeys[0];

      if (this.admin) {
        // In admin mode always emit
        this.$emit('update:id', selectedKey);
        this.$emit('adminInfo', {
          keys: selectedKey,
          is_leaf: e.node.isLeaf !== undefined
        });
      } else if (e.node.isLeaf) {
        // Only emit an id when user clicks on a leaf node in the tree
        this.$emit('update:id', selectedKey);
        // Update URL for deep linking to an item.
        this.$router.push({
          name: 'EDRCategory',
          params: {
            cat_name: this.category,
            item_id: selectedKey,
          }
        });
      } else {
        // Update URL for deep linking to a folder.
        this.$router.push({
          name: 'EDRCategory',
          params: {
            cat_name: this.category,
            item_id: selectedKey,
          }
        });

        this.autoExpandParent = true;
        this.expandedKeys = selectedKeys;
      }
    },
    generateList: function(data) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const key = node.key;
        this.EDRTreeDataList.push({
          key,
          title: key,
        });
        if (node.children) {
          this.generateList(node.children);
        }
      }
    },
    onExpand: function(keys) {
      this.expandedKeys = keys;
      this.autoExpandParent = false;
    },
    getParentKey: function(key, tree) {
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          if (node.children.some(item => item.key === key)) {
            parentKey = node.key;
          } else if (this.getParentKey(key, node.children)) {
            parentKey = this.getParentKey(key, node.children);
          }
        }
      }
      return parentKey;
    }
  },
};
</script>
