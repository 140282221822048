<template>
  <a-page-header :title="table['name']" />

  <a-table
    :columns="table['header']"
    :data-source="table['data']"
    :pagination="false"
    :rowKey="record => record.key"
    bordered
    size="middle"
  />
</template>

<script>

export default {
  name: 'ESDLTable',
  props: {
    table: Object,
  },
};

</script>
