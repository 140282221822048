<template>
  <div
    class="admin-item"
    v-if="hasContents && edd_object_info"
  >
    <a-space direction="vertical" style="width: 100%">
      <a-row>
        <a-col :span="2">Title</a-col>
        <a-col :span="8">
           <a-input
             v-model:value="edd_object_info.title"
           />
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="2">Description</a-col>
        <a-col :span="20">
           <a-input
             v-model:value="edd_object_info.description"
           />
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="2">ESDL</a-col>
        <a-col :span="20">
          <a-upload
            v-model:file-list="esdl_files"
            name="file"
            :multiple="false"
            action=""
            :headers="headers_esdl"
            @change="handleChangeESDL"
            :before-upload="beforeESDLUpload"
          >
            <a-space>
              <a-button>
                <upload-outlined></upload-outlined>
                Click to Upload
              </a-button>
              <a-spin
                v-if="is_esdl_loading"
              />
            </a-space>
          </a-upload>
          <a-textarea
            v-model:value="esdl_text"
            :rows=10
            @blur="handleEsdlTextChanged"
          />
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="2">Tags</a-col>
        <a-col :span="20">
          <template v-for="(tag, index) in edd_object_info.tags" :key="tag">
            <a-tooltip v-if="tag.length > 20" :title="tag">
              <a-tag :class="'tag-color-' + index % 5" :closable="true" @close="handleTagRemove(tag)">
                {{ `${tag.slice(0, 20)}...` }}
              </a-tag>
            </a-tooltip>
            <a-tag v-else :class="'tag-color-' + index % 5" :closable="true" @close="handleTagRemove(tag)">
              {{ tag }}
            </a-tag>
          </template>

          <a-input
            v-if="tagInputVisible"
            ref="tagInputRef"
            type="text"
            size="small"
            :style="{ width: '78px' }"
            v-model:value="tagInputValue"
            @blur="handleTagInputConfirm"
            @keyup.enter="handleTagInputConfirm"
          />
          <a-tag v-else @click="showTagInput" style="background: #fff; border-style: dashed">
            <plus-outlined />
            New Tag
          </a-tag>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="2">Image</a-col>
        <a-col :span="20">
          <a-upload
            v-model:file-list="image_files"
            name="file"
            :multiple="false"
            action=""
            :headers="headers_image"
            @change="handleChangeImage"
            :before-upload="beforeImageUpload"
          >
            <a-space>
              <a-button>
                <upload-outlined></upload-outlined>
                Click to Upload
              </a-button>
              <a-spin
                v-if="is_image_loading"
              />
            </a-space>
          </a-upload>
          <img
            v-if="edd_object_info.image"
            class="admin-asset-image"
            :src="'data:' + edd_object_info.image.contentType + ';base64,' + edd_object_info.image.imageData"
          />
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="2">Annotations:</a-col>
        <a-col :span="20">
           <a-table
            :columns="annotation_columns"
            :data-source="annotation_info"
            :pagination="false"
            :rowKey="record => record.type"
            bordered
            size="middle"
          >
            <template #annotation_renderer="{ record }">
              <a-textarea
                v-model:value="record.explanation"
                :rows="(record.explanation?10:1)"
                @blur="handleAnnotationChanged(record)"
              />
            </template>
          </a-table>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="2">Commit message:</a-col>
        <a-col :span="20">
           <a-input
             v-model:value="edd_object_info.commitMessage"
           />
        </a-col>
      </a-row>
      <a-button v-if="enable_save_and_remove" type="primary" @click="handleSave">Save</a-button>
      <a-button v-if="enable_save_and_remove && !new_item" type="primary" danger @click="handleRemove">Remove</a-button>
      <div v-if="edd_object_info.graphURL != null">
        <iframe :src="edd_object_info.graphURL" width="100%" height="400px" />
      </div>
    </a-space>
  </div>
</template>

<script setup="props, { emit }">
import {ref, reactive, defineProps, defineEmits, toRefs, watchEffect, nextTick, computed} from "vue";
import { PlusOutlined, UploadOutlined } from '@ant-design/icons-vue';
import {message} from "ant-design-vue";

const emit = defineEmits(['update_properties', 'save', 'remove']);
const props = defineProps({
  id: String,
  properties: Object,
  enable_save_and_remove: Boolean,
  new_item: Boolean,
});
// eslint-disable-next-line
const { id, properties, enable_save_and_remove, new_item } = toRefs(props);

const hasContents = ref(false);
const esdl_text = ref("");

const annotation_types = [
    "GENERAL",
    "TECHNICAL_DIMENSIONS",
    "COSTS",
    "ENERGY_FLOWS",
    "MATERIAL_FLOWS",
    "EMISSIONS",
    "OTHER"
];

const annotation_columns = [
  {
    key: 'Type',
    title: 'Type',
    dataIndex: 'type',
    width: 100,
  },
  {
    key: 'Value',
    title: 'Value',
    dataIndex: 'explanation',
    slots: {
      customRender: 'annotation_renderer'
    }
  }
];

const create_full_annotation_table = () => {
  let input_annotations = props.properties.annotation;

  let all_annotations = [];
  for (let i = 0; i < annotation_types.length; i++) {
    let found_explanation = null;
    if (input_annotations) {
      for (let j = 0; j < input_annotations.length; j++) {
        if (input_annotations[j].type === annotation_types[i]) {
          found_explanation = input_annotations[j].explanation;
        }
      }
    }
    all_annotations.push({
      type: annotation_types[i],
      explanation: found_explanation
    });
  }
  return all_annotations;
}

const annotation_info = ref([]);

// eslint-disable-next-line
const handleAnnotationChanged = (record) => {
  if (record.explanation !== null) {
    if (edd_object_info.value.annotation === null) {
      edd_object_info.value.annotation = [];
    }
    for (let i = 0; i < edd_object_info.value.annotation.length; i++) {
      if (edd_object_info.value.annotation[i].type === record.type) {
        edd_object_info.value.annotation[i].explanation = record.explanation;
        return;
      }
    }
    edd_object_info.value.annotation.push(record);
  }
}

watchEffect(() => {
  if (properties.value) {
    esdl_text.value = Buffer.from(properties.value.esdl, 'base64').toString();
    annotation_info.value = create_full_annotation_table()
    hasContents.value = true;
  }
});

const edd_object_info = computed({
  get() {
    return props.properties;
  },
  set(value) {
    emit('update_properties', value);
  }
});

// eslint-disable-next-line
const handleSave = () => {
  // as the tags are not updated automatically in the edd_object_info, copy the information here...
  edd_object_info.value.tags = state.tags;

  if (new_item.value) {
    edd_object_info.value.id = id.value + '/' + edd_object_info.value.title + '.edd';
  }
  emit('save', edd_object_info.value);
}

// eslint-disable-next-line
const handleRemove = () => {
  emit('remove', edd_object_info.value);
}

// =================================================================================================================
//  Tag list functionality
// =================================================================================================================
const tagInputRef = ref();
const state = reactive({
  tags: [],
  tagInputVisible: false,
  tagInputValue: '',
});
// eslint-disable-next-line
const { tags, tagInputVisible, tagInputValue } = toRefs(state);

watchEffect(() => {
  if (properties.value) {
    // esdl_text.value = atob(properties.value.esdl);
    tags.value = [...properties.value.tags];
  }
});

// eslint-disable-next-line
const handleTagRemove = (removedTag) => {
  const tags = state.tags.filter(tag => tag !== removedTag);
  state.tags = tags;
};

// eslint-disable-next-line
const showTagInput = () => {
  state.tagInputVisible = true;
  nextTick(() => {
    tagInputRef.value.focus();
  });
};

// eslint-disable-next-line
const handleTagInputConfirm = () => {
  const inputValue = state.tagInputValue;
  let tags = state.tags;

  if (inputValue && tags.indexOf(inputValue) === -1) {
    tags = [...tags, inputValue];
  }

  Object.assign(state, {
    tags,
    tagInputVisible: false,
    tagInputValue: '',
  });
  edd_object_info.value.tags = tags;
};

// =================================================================================================================
//  Upload image functionality
// =================================================================================================================
// eslint-disable-next-line
const image_files = ref([]);
const is_image_loading = ref(false);

// eslint-disable-next-line
const handleChangeImage = (info) => {
  if (info.file.status !== 'uploading') {
    console.log(info.file, info.fileList);
  }

  if (info.file.status === 'done') {
    message.success(`${info.file.name} file uploaded successfully`);
  } else if (info.file.status === 'error') {
    message.error(`${info.file.name} file upload failed.`);
  }
};

// eslint-disable-next-line
const headers_image = reactive({
    authorization: 'authorization-text',
});

// eslint-disable-next-line
const beforeImageUpload = file => {
  return new Promise(() => {
    is_image_loading.value = true;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      let res = reader.result;
      // eslint-disable-next-line
      let [content_type, data] = res.split(',');
      if ('image' in edd_object_info.value) {
        edd_object_info.value.image.contentType = content_type;
        edd_object_info.value.image.imageData = data;
      } else {
        edd_object_info.value.image = {
          contentType: content_type.split(';')[0],
          imageData: data,
        };
      }
      is_image_loading.value = false;
    };

    return false;
  });
}

// =================================================================================================================
//  upload ESDL functionality
// =================================================================================================================
// eslint-disable-next-line
const esdl_files = ref([]);
const is_esdl_loading = ref(false);

// eslint-disable-next-line
const handleChangeESDL = (info) => {
  if (info.file.status !== 'uploading') {
    console.log(info.file, info.fileList);
  }

  if (info.file.status === 'done') {
    message.success(`${info.file.name} file uploaded successfully`);
  } else if (info.file.status === 'error') {
    message.error(`${info.file.name} file upload failed.`);
  }
};

const handleEsdlTextChanged = () => {
  //edd_object_info.value.esdl = toBinary(esdl_text.value);
  edd_object_info.value.esdl = Buffer.from(esdl_text.value).toString('base64');
}

// eslint-disable-next-line
const headers_esdl = reactive({
    authorization: 'authorization-text',
});

// eslint-disable-next-line
const beforeESDLUpload = file => {
  return new Promise(() => {
    is_esdl_loading.value = true;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      let res = reader.result;
      // eslint-disable-next-line
      let [mime, data] = res.split(',');
      esdl_text.value = Buffer.from(data, 'base64').toString();
      edd_object_info.value.esdl = data;
      is_esdl_loading.value = false;
    };

    return false;
  });
}

</script>

<style>

.admin-item {
  background: #ffffff;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.admin-asset-image {
  height: 200px;
}

.tag-color-0 {
  color: red;
}
.tag-color-1 {
  color: blue;
}
.tag-color-2 {
  color: orange;
}
.tag-color-3 {
  color: green;
}
.tag-color-4 {
  color: purple;
}

</style>