import {createRouter, createWebHistory} from 'vue-router'
import Home from './views/Home.vue'
import Admin from './views/Admin.vue'
import EDRCategory from './views/EDRCategory.vue'
import RedirectOrLogin from './views/RedirectOrLogin.vue'
import Unauthorized from './views/Unauthorized.vue'
import {keycloakAuthBeforeResolveGuard} from "./keycloak/authentication.js";

const routes = [
    // Landing page that does not require you to be logged in.
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            needsAuthentication: false
        }
    },
    {
        path: '/cat/:cat_name/:item_id?',
        name: 'EDRCategory',
        component: EDRCategory,
        meta: {
            needsAuthentication: false
        },
    },
    // Page that requires you to be logged in.
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        meta: {
            needsAuthentication: true,
            roleRequired: 'admin'
        },
    },
    // Route that Keycloak redirects back to, which either forwards you to the page you wanted to visit, or sends you
    // to the login page.
    {
        path: '/redirect_or_login',
        name: 'RedirectOrLogin',
        component: RedirectOrLogin,
        meta: {
            needsAuthentication: false
        },
    },
    // The unauthorized page.
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized,
        meta: {
            needsAuthentication: false
        },
    }
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
})

/**
 * Check authentication before resolving every route.
 */
router.beforeResolve(keycloakAuthBeforeResolveGuard)

export default router
