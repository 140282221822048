<template>
  <div id="app">
    <a-layout id="page_layout">
      <a-layout-header class="header">
        <div class="logo" />
        <div class="account">
          <a-dropdown>
            <template #overlay>
              <a-menu @click="handleUserMenuClick">
                <a-menu-item key="admin">
                  <setting-outlined />
                  Admin
                </a-menu-item>
                <a-menu-item v-if="!isAuthenticated" key="login">
                  Login
                </a-menu-item>
                <a-menu-item v-if="isAuthenticated" key="logout">
                  Logout
                </a-menu-item>
              </a-menu>
            </template>
            <a-button :ghost="true">
              <template #icon><user-outlined /></template>
            </a-button>
          </a-dropdown>
        </div>
        <a-menu
          theme="dark"
          mode="horizontal"
          v-model:selectedKeys="selectedEDRCategoryArray"
          :style="{ lineHeight: '64px' }"
        >
          <a-menu-item key="home">
            <router-link to="/">Home</router-link>
          </a-menu-item>
          <a-menu-item v-for="cat in cat_list" :key="cat">
            <router-link :to="'/cat/' + cat">{{ cat }}</router-link>
          </a-menu-item>
        </a-menu>
      </a-layout-header>
      <a-layout>
        <router-view/>
      </a-layout>
      <a-layout-footer style="text-align: center; color: white; background: black;">
        Energy Data Repository - TNO © 2024
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useEDRCategory } from '@/composables/EDRCategory.js';
import { useKeycloak } from "@/keycloak/authentication.js";

// eslint-disable-next-line
import { UserOutlined, SettingOutlined } from '@ant-design/icons-vue';

// eslint-disable-next-line
const { selectedEDRCategory, setEDRCategory } = useEDRCategory();
// eslint-disable-next-line
const selectedEDRCategoryArray = computed(() => {
  return [selectedEDRCategory.value]
})

// eslint-disable-next-line
const { login, logout, isAuthenticated } = useKeycloak();
const router = useRouter();

// eslint-disable-next-line
const cat_list = [
    "Assets",
    "Profiles",
    "Key figures"
]

// eslint-disable-next-line
const handleUserMenuClick = (e) => {
  if (e.key === 'login') {
    login();
  } else if (e.key === 'logout') {
    logout();
  } else if (e.key === 'admin') {
    router.push('/admin');
  }
}

</script>

<style>
#app {
  height: 100%;
}

#page_layout {
  height: 100%;
}

#page_layout .logo {
  width: 79px;
  height: 31px;
  background-image:url('images/TNO.jpg');
  margin: 16px 28px 16px 0;
  float: left;
}

#page_layout .account {
  float: right;
}

</style>
