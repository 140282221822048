import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const selectedEDRCategory = ref("home");

export function useEDRCategory() {
  const route = useRoute();

  // Initialize from URL if available.
  onMounted(() => {
    if (route.params.cat_name) {
      setEDRCategory(route.params.cat_name)
    }
  });

  // Watch for route changes
  watch(() => route.params.cat_name, (newCategory) => {
    if (newCategory) {
      setEDRCategory(newCategory);
    }
  });

  const setEDRCategory = (cat) => {
    selectedEDRCategory.value = cat;
  };

  return {
    selectedEDRCategory,
    setEDRCategory,
  }
}