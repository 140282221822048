<template>
  <div
    v-if="has_contents"
    class="card-container"
  >
      <a-tabs type="card">
        <a-tab-pane key="tab1" tab="Contents">
          <a-spin :spinning="is_loading" tip="Loading ESDL item...">
          <ESDLAsset v-if="item_category=='Assets'" v-model:id="item_id" v-model:obj_info="esdl_object_info" />
          <ESDLProfile v-else-if="item_category=='Profiles'" v-model:id="item_id" v-model:obj_info="esdl_object_info" />
          <ESDLKeyFigures v-else-if="item_category=='Key figures'" v-model:id="item_id" v-model:obj_info="esdl_object_info" />
          <div v-else>
            <a-card>
              <p>Category not supported: {{ item_category }} {{item_category == 'Assets'}}</p>
            </a-card>
          </div>
          </a-spin>
        </a-tab-pane>
        <a-tab-pane key="versions" tab="Versions" v-if="version_info">
          <ESDLVersions v-model:id="item_id" v-model:version_info="version_info"/>
        </a-tab-pane>
        <a-tab-pane v-if="source_info" key="tab2" tab="Source">
          <ESDLSource v-model:id="item_id" v-model:source_info="source_info" />
        </a-tab-pane>
        <a-tab-pane key="tab3" tab="ESDL">
          <ESDLViewer v-model:obj_info="esdl_object_info" v-model:esdl_string="esdl_string" />
        </a-tab-pane>
      </a-tabs>
  </div>
  <div v-else>
    <a-card style="height:100%">
      <a-spin v-if="is_loading" tip="Loading ESDL item...">
        <div class="loading-placeholder"></div>
      </a-spin>
      <template v-else>
        Please select an item from the menu at the left.
      </template>
    </a-card>
  </div>
</template>

<script setup>
import axios from 'axios'
import { API_HOST } from "../envconfig"
import ESDLAsset from './ESDLAsset.vue'
import ESDLProfile from './ESDLProfile.vue'
import ESDLKeyFigures from './ESDLKeyFigures.vue'
import ESDLSource from './ESDLSource.vue'
import ESDLViewer from './ESDLViever.vue'
import ESDLVersions from './ESDLVersions.vue'
import { ref, computed, watch } from 'vue'
import {useEDRCategory} from "@/composables/EDRCategory";

const { selectedEDRCategory } = useEDRCategory();

const props = defineProps({
  id: String
})

const esdl_object_info = ref({})
const is_loading = ref(false)

const item_id = computed(() => props.id)
const item_category = computed(() => selectedEDRCategory.value)
const has_contents = computed(() => Object.keys(esdl_object_info.value).length !== 0)
const esdl_string = computed(() => esdl_object_info.value['esdl_string'])
const source_info = computed(() => esdl_object_info.value['source_info'])
const version_info = computed(() => esdl_object_info.value['version_info'])

const get_ESDL_item = (id) => {
  const path = `${API_HOST}/api/edr_item?id=` + encodeURIComponent(id)
  console.debug("Getting ESDL item: " + path)
  is_loading.value = true
  axios.get(path)
    .then((res) => {
      console.log(res)
      esdl_object_info.value = res['data']
      is_loading.value = false
    })
    .catch((error) => {
      is_loading.value = false
      // If 404, silently ignore.
      if (error.response.status === 404) {
        console.debug("ESDL item not found: " + id)
        esdl_object_info.value = {}
        return
      }
      console.error(error)
    })
}

// Watchers
watch(() => props.id, (val) => {
  if (val !== undefined) {
    get_ESDL_item(val)
  }
})

watch(() => selectedEDRCategory.value, (val) => {
  console.debug("Category changed: " + val)
  if (val) {
    esdl_object_info.value = {}
  }
})
</script>

<style>
.card-container {
  background: #f5f5f5;
  padding: 24px;
  height: 100%
}

.card-container > .ant-tabs-card {
  height: 100%;
}

.ant-tabs-content-holder {
  overflow: auto;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
  height: calc(100% - 50px);
  margin-top: -16px;
  background: #fff;
  padding: 16px;
  box-sizing: border-box;
  overflow: auto;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}

.loading-placeholder {
  height: 200px;
}
</style>