<template>
  <div
    v-if="type in annotation_dict"
    class="annotation-explanation"
  >
    <h4 class="annotation-title">{{ title }}</h4>
    {{ annotation_dict[type] }}
  </div>
</template>

<script>

export default {
  name: 'ESDLAnnotation',
  props: {
    annotation_dict: Object,
    type: String,
    title: String
  },
};

</script>

<style>

.annotation-title {
  color: black;
  font-weight: bold;
}

.annotation-explanation {
  border: solid 1px lightgray;
  color: gray;
  display: block;
  margin: 10px;
  padding: 10px;
}
</style>