import { createApp } from 'vue'
import App from './App.vue';
import router from './router.js';
import 'ant-design-vue/dist/antd.min.css'
import { Buffer } from 'buffer'
import { Breadcrumb, Button, Card, Col, Dropdown, Input, Layout, Menu, PageHeader, Radio, Row, Select, Space, Spin,
    Table, Tabs, Tag, Tooltip, Tree, TreeNode, Upload, message } from 'ant-design-vue';
import {initKeycloak} from "@/keycloak/authentication.js"

const app = createApp(App);
app.config.productionTip = false;

app.use(Breadcrumb);
app.use(Button);
app.use(Card);
app.use(Col);
app.use(Dropdown);
app.use(Input);
app.use(Layout);
app.use(Menu);
app.use(PageHeader);
app.use(Radio);
app.use(Row);
app.use(Select);
app.use(Space);
app.use(Spin);
app.use(Table);
app.use(Tabs);
app.use(Tag);
app.use(Tooltip);
app.use(Tree);
app.use(TreeNode);
app.use(Upload);
app.config.globalProperties.$message = message;

app.use(router);

globalThis.Buffer = Buffer;

initKeycloak({
    checkLoginIframe: false,
    // Check login status.
    onLoad: 'check-sso',
    // Check status in the background, without a full page refresh.
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
}).then(() => {
    // NOTE: Only when keycloak is initialized, the app will actually load. This is done so that we always know the
    // accurate authentication status.
    // If you do not need this, for example because authentication is only a small part of your application, perform
    // the below outside this then-callback.
    app.mount('#app');
})
