<template>
  Admin: {{ firstName }} {{ lastName }}

  <a-layout>
    <a-layout-sider width="350" :style="{ overflow: 'auto', background: '#fff' }">
      <EDRTree
        :admin="true"
        v-model:id="selectedEDRItem"
        v-model:category="selectedEDRCategory"
        @adminInfo="processAdminInfo"
      />
    </a-layout-sider>
    <div
      class="card-container"
      v-if="selectedEDRItem"
    >
      <AdminItem
        v-if="isDirectoryOrItem == 'item'"
        class="admin-item"
        v-model:id="selectedEDRItem"
        :new_item="false"
        :profile_item="false"
        @item_deleted="item_deleted"
      />
      <AdminItem
        v-if="isDirectoryOrItem == 'new_item'"
        class="admin-item"
        v-model:id="selectedEDRItem"
        :new_item="true"
        :profile_item="false"
        @new_item_created="new_EDD_item_created"
      />
      <AdminItem
        v-if="isDirectoryOrItem == 'new_profile_item'"
        class="admin-item"
        v-model:id="selectedEDRItem"
        :new_item="true"
        :profile_item="true"
        @new_item_created="new_EDD_item_created"
      />
      <AdminDir
        v-if="isDirectoryOrItem == 'directory'"
        v-model:id="selectedEDRItem"
        @create_new_EDD="create_new_EDD"
        @create_new_profile_EDD="create_new_profile_EDD"
      />
    </div>
    <div
      class="card-container"
      v-else
    >
      Please select a folder or item on the left to continue...
    </div>
  </a-layout>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue';
// eslint-disable-next-line
import EDRTree from '../components/EDRTree.vue'
// eslint-disable-next-line
import AdminItem from '../components/AdminItem.vue'
// eslint-disable-next-line
import AdminDir from '../components/AdminDir.vue'
import { useKeycloak } from "@/keycloak/authentication.js";
import { useEDRTree } from '@/composables/EDRTree.js';

const { getKeycloak } = useKeycloak();
const { addNode } = useEDRTree();

const firstName = ref();
const lastName = ref();

const isDirectoryOrItem = ref("");
// eslint-disable-next-line
const selectedEDRCategory = ref("/");

// eslint-disable-next-line
const selectedEDRItem = ref();
// eslint-disable-next-line
const adminInfo = reactive({});

onBeforeMount(() => {
  const kc = getKeycloak();

  firstName.value = kc.tokenParsed.given_name
  lastName.value = kc.tokenParsed.family_name
})

// eslint-disable-next-line
const processAdminInfo = (e) => {
  if (e.is_leaf) {
    isDirectoryOrItem.value = 'item';
  } else {
    isDirectoryOrItem.value = 'directory';
  }
}

// eslint-disable-next-line
const create_new_EDD = () => {
  isDirectoryOrItem.value = 'new_item';
}

// eslint-disable-next-line
const create_new_profile_EDD = () => {
  isDirectoryOrItem.value = 'new_profile_item';
}

const new_EDD_item_created = (parameters) => {
  let name = parameters.name;
  if (!(name.endsWith('.edd'))) {
    name = name + '.edd';
  }
  addNode(parameters.folder, name);
  selectedEDRItem.value = parameters.folder + '/' + name;
}

// eslint-disable-next-line
const item_deleted = (parameters) => {
  // This message is sent after selecting the parent of the deleted file in the EDRTree
  // The following lines sets the proper selectedEDRItem value and triggers the directory view
  let new_selected_dir = parameters.new_selected_dir;
  selectedEDRItem.value = new_selected_dir;
  isDirectoryOrItem.value = 'directory';
}

</script>

<style>

.admin-item {
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 24px 24px 24px 24px;
}

.card-container {
  background: #f5f5f5;
  padding: 24px;
  width: 100%;
  height: 100%;
}

</style>